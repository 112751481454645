import * as PIXI from 'pixi.js'
import store from '@/store'
import pointer from '@/assets/pointer.png';

let sound;
let socket;
let ownerID;
let pixi_app;
let end_flag;
let is_suspend;
let start_time;
let cursor_pos;
let suspend_time;
let pieceSprites;
let suspend_sprite;
let borderless_arr;
let cursors_sprite;
let grabbingPieces;
let connectionTimer;
let reconnectInterval;
let multiplayerDetail;
let ownerConnectionTimer;

// vuexがlocalStorageを見に行って、保存されていなければ新規に生成する
const clientID = store.state.clientID;
localStorage.setItem('clientID', clientID);

function cleanAllVariable() {
    socket.down();
    clearTimeout(connectionTimer);
    clearTimeout(ownerConnectionTimer);


    pixi_app = null;
    end_flag = false;
    is_suspend = false;
    start_time = 0;
    suspend_time = 0;
    sound = null;
    suspend_sprite = null;
    pieceSprites = []
    borderless_arr = []
    cursor_pos = {x:0, y:0}
    cursors_sprite = []
    ownerID = null;
    grabbingPieces = {};
    multiplayerDetail;
    socket = null;
    reconnectInterval = 1250;
    connectionTimer = null;
    ownerConnectionTimer = null;
}


// もっと抽象化するべき
// class GrabbingList{
//     constructor(){
//         this.pieces = [];
//     }

//     add(piece) {
//         this.pieces.push(piece);
//     }

//     get() {
//         return this.pieces.splice(0);
//     }
// }


async function draw_puzzle(status, puzzle_size_str='1000x750', is_land_scape=true, padding=500, savedData=null){
    const isSudden = status['isSudden']
    const padding_size = is_land_scape ? {'row': padding, 'column':0} : {'row':0, 'column': padding}

    let piece_num = status.pcs
    const puzzle_size = {'row': Number(puzzle_size_str.split('x')[0]), 'column': Number(puzzle_size_str.split('x')[1])}
    const piece_size = (puzzle_size.row * puzzle_size.column / piece_num) ** 0.5

    pixi_app = await init_pixi()
    const base_arr = create_puzzle_base()

    const map_pos = {'lng': status.lng, 'lat': status.lat}
    let url = get_mapurl(puzzle_size_str, map_pos, status.zoom, status.map)

    let position_arr = []

    create_piece(url).then(() => {
        suspend_sprite = create_suspend_sprite()

        // マウスカーソルの位置を定時送信する
        let grabbing_piece=null;
        document.addEventListener('grabbingEvent', (e)=>{grabbing_piece=e.detail.name;})
        document.addEventListener('outGrabEvent' , ()=>{grabbing_piece=null;})
        document.addEventListener('pointermove', (e)=>{save_position(e)});

        let position = {x:0, y:0};
        const domRect = document.getElementById('puzzle-main-webgl').querySelector('canvas').getBoundingClientRect();   
        function save_position(event) {
            if (pixi_app === null) return false;
            const canvasRatio = pixi_app.screen.height / domRect.height;
            const x = (canvasRatio * (event.clientX - domRect.x))|0;
            const y = (canvasRatio * (event.clientY - domRect.y))|0;

            position.x = (0 <= x && x <= pixi_app.screen.width) ? x : position.x;
            position.y = (0 <= y && y <= pixi_app.screen.height) ? y : position.y;
        }

        if (savedData !== null) {
            ownerRepeatHandler(savedData);
        }


        // 情報の定時送信
        function ownerBroadcast() {
            if (socket === null) return false;
            if (socket.readyState === WebSocket.OPEN) {
                if (ownerID == clientID) {
                    const piecesDetail = pieceSprites.map(piece => {
                        return {
                            name: piece['name'],
                            isCollect: !piece.visible,
                            position: {x: piece.x, y: piece.y}
                        }
                    })
                    // 接続が切断されたユーザーは一覧から削除する
                    // console.log(piecesDetail)

                    // ピースのリスト: 座標、正解
                    const message = JSON.stringify({ 
                        id:clientID, 
                        type:'ownerRepeat',
                        isEnded: end_flag,
                        isSuspending: is_suspend,
                        startTime: start_time,
                        suspendingTime: suspend_time,
                        grabbingPieces: grabbingPieces,
                        piecesDetail: piecesDetail,
                        puzzleDetail: status,
                        nowScore: getNowScore(start_time, suspend_time),
                    });
                    socket.send(message);
                    localStorage.setItem('multiplayerDetail', JSON.stringify({
                        savedDate: Date.now(),
                        passphrase: store.state.multiplayer.passphrase,
                        ownerID: store.state.multiplayer.ownerID,
                        puzzleDetail: status,
                        piecesDetail: piecesDetail,
                        mapStatus: store.state.mapStatus,
                        score: getNowScore(start_time, suspend_time),
                    }));
                }
            }
            if (socket !== null) setTimeout(ownerBroadcast, 500);
        }

        function clientBroadcast() {
            if (socket === null) return false;
            if (socket.readyState === WebSocket.OPEN) {
                const x = position.x
                const y = position.y
                cursor_pos.x = (0 <= x && x <= pixi_app.screen.width) ? x : cursor_pos.x
                cursor_pos.y = (0 <= y && y <= pixi_app.screen.height) ? y : cursor_pos.y

                const message = JSON.stringify({ 
                    id:clientID, 
                    type:'clientRepeat', 
                    x: cursor_pos.x, 
                    y: cursor_pos.y, 
                    grabbing: grabbing_piece 
                });
                socket.send(message);
            }
            if (socket !== null) setTimeout(clientBroadcast, 35);
        }

        setTimeout(ownerBroadcast, 500);
        setTimeout(clientBroadcast, 35);
        // ピースを掴んでから離すまでの間に送信しないと情報を捕捉できなくなる
      }
    )

    const repeatDataHandler = (e) => {
        const data = e.detail;
        const sprite = pieceSprites.find((sprite) => sprite.name === data.grabbing);

        let beforeGrabbing = {};
        Object.assign(beforeGrabbing, grabbingPieces);

        if (sprite) {
            move_sprite(pixi_app, sprite, base_arr.xy, true, {x: data.x, y: data.y});
            grabbingPieces[data.id] = data.grabbing;
        } else {
            grabbingPieces[data.id] = null;
        }

        if (ownerID === clientID) {
            // 更新前後での差分（grabbingではなくなったピース）を抽出する
            const outGrabPieces = Object.values(beforeGrabbing).filter(name => !Object.values(grabbingPieces).includes(name))
            // console.log(outGrabPieces)
            outGrabPieces.forEach(piece => {
                // if(piece) release_sprite(pixi_app, pieceSprites.find(ps => ps.name === piece), base_arr.xy, true);
                release_sprite(pixi_app, pieceSprites.find(ps => ps.name === piece), base_arr.xy, true);
            })
        }

        refresh_cursors();

        function refresh_cursors() {
            const cursor = cursors_sprite.find(cursor => cursor.id === data.id);
            if (cursor) {
                cursor.sprite.x = data.x;
                cursor.sprite.y = data.y;
                cursor.sprite.zIndex = 1000;
            } else {        
                const sprite = new PIXI.Sprite.from(pointer);   
                sprite.anchor.x = 0;
                sprite.anchor.y = 0;
                sprite.x = data.x;
                sprite.y = data.y;
                sprite.zIndex = 1;
                pixi_app.stage.sortChildren();
        
                cursors_sprite.push({
                    id: data.id,
                    sprite: sprite,
                })
                add_sprite([sprite]);
            }
        }
    }

    const ownerRepeatHandler = (e) => {
        const data = e.detail;

        start_time = data.startTime;
        suspend_time = data.suspendingTime;
        const piecesDetail = data.piecesDetail;
        const grabbing = data.grabbingPieces;

        // O(N^2)
        for (let pdi=0; pdi < piecesDetail.length; pdi++) {
            const detail = piecesDetail[pdi];

            // ピースが誰かに掴まれている間は矯正しない
            if (!Object.values(grabbing).includes(detail.name)) {
                let piece;
                for (let psi=0; psi < pieceSprites.length; psi++) {
                    if (detail.name === pieceSprites[psi].name) {
                        piece = pieceSprites[psi];
                        break
                    }
                }

                if (piece) {
                    if (!piece.dragging) {
                        move_sprite(pixi_app, pieceSprites.find(piece => piece.name === detail.name), base_arr.xy, true, detail.position);
                    
                        if(detail.isCollect) {
                            // 音を鳴らさないのであればcollect(piece)だけでよい
                            // 音を鳴らすために、わざわざ当該ピースがすでにはまっているか検査する
                            if (piece.visible === true) {
                                collect(piece, sound);
                                position_arr = update_position_arr();
                            }
                        } else {
                            unCollect(piece);
                        }
                    }
                }

            }
        }
        if (!end_flag) check_collect_all_pieces();

        // 位置の同期 OK
        // 正誤の同期 OK
        // 時間の同期 OK
        // 中断状態の同期 not yet
    }


    document.addEventListener('receiveRepeatData', repeatDataHandler);
    document.addEventListener('receiveOwnerRepeat', ownerRepeatHandler);

    async function init_pixi() {
        const width = puzzle_size.row + padding_size.row
        const height = puzzle_size.column + padding_size.column
        let app = new PIXI.Application({
            width: width,
            height: height,
            backgroundColor: 0xFFFFFF,
        });
        let el = document.getElementById('puzzle-main-webgl');
        el.appendChild(app.view);
        app.sortableChilderen = true

        app.view.style.width = '95vw'
        app.view.style.height = 'auto'
        app.view.style['max-width'] = `${width}px`
        app.view.style['max-height'] = `${height}px`

        app.renderer.plugins.interaction.autoPreventDefault = false;
        app.renderer.view.style.touchAction = 'auto';

        return new Promise(resolve => {resolve(app)})
    }

    function create_puzzle_base(){
        const xy_d = create_base_position()

        const canvas = document.createElement('canvas')
        canvas.width = puzzle_size.row
        canvas.height = puzzle_size.column

        const ctx = canvas.getContext("2d")
        ctx.fillStyle = 'whitesmoke';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        let i = 0
        while (i < xy_d.xy.length) {
            const x = xy_d.x[i] - piece_size/2
            const y = xy_d.y[i] - piece_size/2
            ctx.strokeStyle = 'lightgray'
            ctx.strokeRect(x,y, piece_size, piece_size)
            i=(i+1)|0
        }
        const image_url = canvas.toDataURL('image/png')

        const sprite = new PIXI.Sprite.from(image_url);
        sprite.anchor.x = 0.5;
        sprite.anchor.y = 0.5;
        sprite.x = puzzle_size.row/2
        sprite.y = puzzle_size.column/2
        add_sprite([sprite])

        function create_base_position(){
            const xy_arr = []
            const x_arr = []
            const y_arr = []
            // 土台が作成される起点の座標
            const default_x = 0 + piece_size/2
            const default_y = 0 + piece_size/2
            const max_x = puzzle_size.row

            let n = 0
            let x = default_x
            let y = default_y
            while (n < piece_num) {
                const pos = [x,y]
                xy_arr.push(pos)
                x_arr.push(x)
                y_arr.push(y)

                if (x+piece_size > max_x) {
                    x = default_x
                    y += piece_size
                } else {
                    x += piece_size
                }
                n=(n+1)|0
            }

            const xy_dict = {'xy':xy_arr, 'x':x_arr, 'y':y_arr}
            return xy_dict
        }
        return xy_d
    }

    async function create_piece(map_url){
        let row = 0
        let column = 0

        const response = await fetch(map_url)
        response.ok ? map_url = await response.blob() : console.log(`HTTP-Error: ${response.status}`)
        map_url = URL.createObjectURL(map_url)
        const map_image = new Image()
        sound = sound_init()
        map_image.addEventListener('load',function (){
            for (let i=0; i <= piece_num-1; i++){
                const texture = {'plane':convert_image_size(map_image, piece_size, row, column),
                    'bordered':draw_piece_border(map_image, piece_size, row, column)}

                let position = {'x':0, 'y':0}
                // ピースが画面外に出ないように座標を調整
                if (padding_size.row) {
                    position.x = puzzle_size.row + Math.floor(Math.random()*(padding_size.row - piece_size*2))+piece_size
                    position.y = Math.floor(Math.random()*(puzzle_size.column - piece_size*2))+piece_size
                } else {
                    position.x = Math.floor(Math.random()*(puzzle_size.row - piece_size*2))+piece_size
                    position.y = puzzle_size.column + Math.floor(Math.random()*(padding_size.column - piece_size*2))+piece_size
                }
                const sprite = create_piece_sprite(pixi_app, texture.bordered, position.x, position.y, row, column, piece_size)
                const borderless_sprite = create_piece_sprite(pixi_app, texture.plane, row, column, row, column, piece_size, false)

                borderless_arr.push(borderless_sprite)
                pieceSprites.push(sprite)
                if (row == puzzle_size.row - piece_size) {
                    row = 0
                    column += piece_size
                } else {
                    row += piece_size
                }
            }
            // 配列を値渡しで複製する
            const shuffled_sprites = pieceSprites.slice()
            add_sprite(shuffle_array(shuffled_sprites))
            add_sprite(borderless_arr)

            isSudden ? select_one_piece() : false

            const completePiecesPrepare = new CustomEvent('completePiecesPrepare');
            document.dispatchEvent(completePiecesPrepare);

            // return new Promise(resolve => {resolve()})
        })
        map_image.src = map_url

        return new Promise((res) => {
            document.addEventListener('completePiecesPrepare', res, { once: true })
        })

    }

    function select_one_piece() {
        const selected_sprite = pieceSprites[getRandomInt(piece_num -1)]
        collect(selected_sprite).then()
    }

    function add_sprite(sprites) {
        let i = 0
        while (i < sprites.length) {
            pixi_app.stage.addChild(sprites[i]);
            i=(i+1)|0
        }
    }

    function create_suspend_sprite() {
        const width = puzzle_size.row + padding_size.row
        const height = puzzle_size.column + padding_size.column

        const canvas = document.createElement('canvas')
        canvas.width = width
        canvas.height = height

        const ctx = canvas.getContext("2d")
        ctx.strokeStyle = '#222'
        ctx.lineWidth = width > height ? width*10 : height*10
        ctx.strokeRect(0,0, width, height)

        ctx.font = '64px gothic'
        ctx.textAlign = "center"
        ctx.fillStyle = '#DDD'
        ctx.fillText('みせられないよ！', width/2, height/2)

        const image_url = canvas.toDataURL('image/png')
        let sprite = new PIXI.Sprite.from(image_url);

        sprite.anchor.x = 0;
        sprite.anchor.y = 0;
        sprite.x = 0
        sprite.y = 0
        sprite.zIndex = 5
        sprite.visible = false
        add_sprite([sprite])
        return sprite
    }

    function convert_image_size(image, size, row, column){
        const canvas = document.createElement('canvas')
        canvas.width = size
        canvas.height = size

        const ctx = canvas.getContext("2d")
        // sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight
        ctx.drawImage(image, row, column, size, size, 0, 0, size, size)

        const image_url = canvas.toDataURL('image/png')
        return image_url
    }

    function draw_piece_border(image, size, row, column){
        const canvas = document.createElement('canvas')
        canvas.width = size
        canvas.height = size

        const ctx = canvas.getContext("2d")
        ctx.drawImage(image, row, column, size, size, 0, 0, size, size)
        ctx.strokeStyle = 'black'
        ctx.strokeRect(0,0, size, size)

        const image_url = canvas.toDataURL('image/png')
        return image_url
    }

    function sound_init(){
        const ussr = new Audio(require('@/assets/sound/otoware_ussr_kokka.mp3'))
        const click1 = new Audio(require('@/assets/sound/click1.mp3'))
        const click2 = new Audio(require('@/assets/sound/click2.mp3'))
        const glass = new Audio(require('@/assets/sound/glass_broken.mp3'))

        return [ussr, click1, click2, glass]
    }

    // function dispatchMoveEvent(sprite) {
    //     const moveOnCanvasEvent = new CustomEvent('moveOnCanvas', {detail: sprite});
    //     document.dispatchEvent(moveOnCanvasEvent);
    // }

    function create_piece_sprite(app, tex, x=0, y=0, l, c, size, is_bordered=true) {
        // なんか追加した
        // https://qiita.com/geregeregere/items/07c946c1bafb36e4d267
        const texture = PIXI.Texture.from(tex);
        texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
        let sprite = new PIXI.Sprite.from(texture);
        sprite.x = x
        sprite.y = y

        if (is_bordered) {
            sprite.anchor.x = 0.5;
            sprite.anchor.y = 0.5;

            sprite.zIndex = 1
            // nameにピースの正しい位置情報を入れておく
            sprite.name = `${l + size / 2},${c + size / 2}`

            sprite.interactive = true
            sprite.buttonMode = true

            sprite.on('mousedown', (function(e){click_sprite(app, sprite, e)}))
            sprite.on('mouseup', (function(){release_sprite(app, sprite, base_arr.xy)}))
            sprite.on('mouseupoutside', (function(){release_sprite(app, sprite, base_arr.xy)}))
            // sprite.on('mousemove', (function(){dispatchMoveEvent(sprite);move_sprite(app, sprite, base_arr.xy, false)}))
            sprite.on('mousemove', (function(){move_sprite(app, sprite, base_arr.xy, false)}))

            sprite.on('touchstart', (function(e){click_sprite(app, sprite, e)}))
            sprite.on('touchend', (function(){release_sprite(app, sprite, base_arr.xy)}))
            sprite.on('touchendoutside', (function(){release_sprite(app, sprite, base_arr.xy)}))
            // sprite.on('touchmove', (function(){dispatchMoveEvent(sprite);move_sprite(app, sprite, base_arr.xy, false)}))
            sprite.on('touchmove', (function(){move_sprite(app, sprite, base_arr.xy, false)}))




        } else {
            sprite.visible = false
        }

        function click_sprite(app, sprite, event) {
            sprite.data = event.data
            // sprite.alpha = 0.5
            sprite.dragging = true

            sprite.zIndex = 5
            app.stage.sortChildren()

            const grabbingEvent = new CustomEvent('grabbingEvent', {detail: sprite});
            document.dispatchEvent(grabbingEvent);
        }


        return sprite
    }

    function update_position_arr() {
        let position_arr = pieceSprites.map(s => ('' + s.x + s.y))
        return position_arr
    }

    function release_sprite(app, sprite, base_pos, isRemote=false) {
        sprite.dragging = false
        sprite.data = null

        sprite.zIndex = 1
        app.stage.sortChildren()
        position_arr = update_position_arr()

        const fixed_pos = {'x': sprite.name.split(',')[0], 'y': sprite.name.split(',')[1]}
        if (fixed_pos.x == sprite.x && fixed_pos.y == sprite.y) {
            collect(sprite, sound).then()
            check_collect_all_pieces()
        } else {
            if (isSudden){
              const found = base_pos.find(pos => pos[0] == sprite.x && pos[1] == sprite.y)
              found ? finnish_puzzle(true, sound[0]) : false
            }
        }

        if (!isRemote) {
            const outGrabEvent = new CustomEvent('outGrabEvent', {detail: sprite});
            document.dispatchEvent(outGrabEvent);
        }
    }

    function move_sprite(app, sprite, base_pos, isRemote=false, position=null) {
        if (sprite.dragging || isRemote) {
            // すでに記録されていたらなにもしない 記録されていなかったらタイマーを起動する
            // マルチプレイ対応のため、起動タイミングを"ピースを掴んだとき"から"ピースを動かしたとき"に変更
            start_time || timer_start()

            if (position === null) {
                position = sprite.data.getLocalPosition(sprite.parent);
            }
            // let position = sprite.data.getLocalPosition(sprite.parent)
            const x = position.x
            const y = position.y
            // 位置変更
            sprite.x = (0 <= x && x <= app.screen.width) ? x : sprite.x
            sprite.y = (0 <= y && y <= app.screen.height) ? y : sprite.y

            const snap_range = piece_size / 3

            //snapさせる処理
            const found = base_pos.find(b =>
                (b[0] + snap_range) >= x && (b[0] - snap_range) <= x &&
                (b[1] + snap_range) >= y && (b[1] - snap_range) <= y
            )

            if (found) {
                // snap先にすでにピースがあったときにはsnapしない
                if (!(position_arr.includes(`${found[0]}${found[1]}`))) {
                    sprite.x = found[0]
                    sprite.y = found[1]
                }
            }
        }
    }

    async function collect(sprite, sound=null){
      // 非表示にしてあるボーダー無しピースの場所を特定する
      const sx = ((parseFloat(sprite.name.split(',')[0]) + piece_size/2) / piece_size)
      const sy = ((parseFloat(sprite.name.split(',')[1]) + piece_size/2) / piece_size - 1) * (puzzle_size.row / piece_size)
      const sxy = sx + sy - 1
      // ボーダー無しピースを表示させてボーダーを消したように見せる
      borderless_arr[sxy].visible = true
      sprite.visible = false
      sprite.interactive = false
      sprite.buttonMode = false

      // スプライトの位置を正しい位置に矯正する とくにサドンデスモード時
      sprite.x = borderless_arr[sxy].x + piece_size/2
      sprite.y = borderless_arr[sxy].y + piece_size/2

    //   const rand = Math.random()
      if (sound) {        
        sound[2].play()
        // if (rand < 0.5) {
        //   sound[1].play()
        // } else if (rand < 0.999) {
        //   sound[2].play()
        // } else {
        //   sound[3].play()
        // }
      }
      return new Promise(resolve => {resolve()})
    }

    function unCollect(sprite) {
        // 正解にしてしまったピースを元に戻す関数
        const sx = ((parseFloat(sprite.name.split(',')[0]) + piece_size/2) / piece_size)
        const sy = ((parseFloat(sprite.name.split(',')[1]) + piece_size/2) / piece_size - 1) * (puzzle_size.row / piece_size)
        const sxy = sx + sy - 1

        borderless_arr[sxy].visible = false
        sprite.visible = true
        sprite.interactive = true
        sprite.buttonMode = true

        // 意図がよくわからないのでコメントアウト
        // sprite.x = borderless_arr[sxy].x + piece_size/2
        // sprite.y = borderless_arr[sxy].y + piece_size/2
    }

    function check_collect_all_pieces(){
        const default_x = 0 + piece_size/2
        const default_y = 0 + piece_size/2
        const max_x = puzzle_size.row

        let finish = true
        let n = 0
        let x = default_x
        let y = default_y

        // 進行状況の同期前に判定しないようにする
        if(pieceSprites.length === 0){
            return false;
        }

        while (n < pieceSprites.length) {
            const sprite_x = pieceSprites[n].position.x
            const sprite_y = pieceSprites[n].position.y

            if (sprite_x == x && sprite_y == y) {
                if (sprite_x + piece_size > max_x) {
                    x = default_x
                    y += piece_size
                } else {
                    x += piece_size
                }
            } else {
                finish = false
                break
            }
            n=(n+1)|0
        }
        if (finish) {
            finnish_puzzle()
        }
    }

    const timer_space = document.getElementById('timer_space')
    const timer_elem = document.createElement('p')
    timer_elem.textContent = '0'
    timer_elem.id = 'timer'

    timer_space.appendChild(timer_elem)
    count(suspend_time)

    function finnish_puzzle(is_game_over=false, sound=null){
        timer_stop()
        if (is_game_over) {
            popup(is_game_over)
            notInteractiveAllPieces()
            sound.play()
        } else {
            const clearTime = timer_elem.textContent
            popup(is_game_over, clearTime)
        }
    }
}

function notInteractiveAllPieces() {
    pieceSprites.forEach(sprite => {
        sprite.interactive = false
        sprite.buttonMode = false
    })
}

function timer_start() {
    start_time = Date.now()
    return start_time
}

function timer_stop() {
    end_flag = true
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function count(suspend_time) {
    const timer_elem = document.getElementById('timer')
    if (timer_elem){
        const now_score = getNowScore(start_time, suspend_time);
        setTimeout(function (){
            timer_elem.textContent = end_flag || is_suspend ? `${now_score}` : count(suspend_time)
        },5)
        return start_time ? `${now_score}` : is_suspend ? `${now_score}(中断中)` : '0'
    } else {
        return false
    }
}

function getNowScore(start, suspend) {
    return (Math.ceil((Date.now() - start + suspend) / 100) / 10).toFixed(1)
}

function shuffle_array(arr) {
    const array = arr
    for (let i = array.length; 1 < i; i--) {
        const k = Math.floor(Math.random() * i);
        [array[k], array[i - 1]] = [array[i - 1], array[k]];
    }
    return array
}

function get_mapurl(size='800x600', pos, zoom, mapid){
    let map_url = `https://sushi334.com/maps?center=${pos.lng},${pos.lat}&zoom=${zoom}&mapid=${mapid}&size=${size}`;
    return map_url
}

function wsInit(url) {
    function connectionTimerStart(timer, timeout) {
        timer = setTimeout(() => {
            if (socket !== null) {
                if (socket.readyState !== WebSocket.OPEN && clientID != ownerID) {
                    // 参加者側 接続不可 
                    const notReceiveOwnerPacket = new CustomEvent('notReceiveOwnerPacket');
                    document.dispatchEvent(notReceiveOwnerPacket);
                } else if (socket.readyState !== WebSocket.OPEN) {
                    // 鯖主側
                    const connectionLost = new CustomEvent('connectionLost');
                    document.dispatchEvent(connectionLost);
                }
            }
        }, timeout);
        return timer;
    }

    function ownerConnectionTimerStart(timer, timeout) {
        timer = setTimeout(() => {
            if (socket !== null) {
                if (socket.readyState === WebSocket.OPEN && clientID != ownerID) {
                    // 参加者側 鯖主不在
                    const ownerHasGone = new CustomEvent('ownerHasGone');
                    document.dispatchEvent(ownerHasGone);
                }
            }
        }, timeout)
        return timer;
    }

    function connectionTimerClear(timer) {
        const receivePacket = new CustomEvent('receivePacket');
        document.dispatchEvent(receivePacket);
        clearTimeout(timer);
    }

    function ownerConnectionTimerClear(timer) {
        clearTimeout(timer);
    }

    function connectionOpen() {
        console.log('the connection has been open!');
    }

    function onMessage(event) {
        // 5秒以上情報を取得できないときは通信中画面を表示する
        connectionTimerClear(connectionTimer);
        connectionTimer = connectionTimerStart(connectionTimer, 5000);
        const parsedData = JSON.parse(event.data);
        if (parsedData.id !== clientID) {
            if (parsedData.type === 'clientRepeat') {
                const receiveRepeatEvent = new CustomEvent('receiveRepeatData', {detail: parsedData});
                document.dispatchEvent(receiveRepeatEvent);
                // console.log(parsedData);
            } else if (parsedData.type === 'ownerRepeat') {
                ownerConnectionTimerClear(ownerConnectionTimer);
                ownerConnectionTimer = ownerConnectionTimerStart(ownerConnectionTimer, 5000);
                const receiveRepeatEvent = new CustomEvent('receiveOwnerRepeat', {detail: parsedData});
                document.dispatchEvent(receiveRepeatEvent);
                // console.log(parsedData);
            } else {
                console.log(`Unrecognised message: ${parsedData}`);
            }
        }

    }

    function reconnection() {
        setTimeout(()=>{
            console.log(`reconnecting... Next: ${reconnectInterval}s`)
            wsInit(url);
        }, reconnectInterval);
        reconnectInterval <= 60000 ? reconnectInterval*=2 : reconnectInterval;
    }

    socket = new WebSocket(url);
    connectionTimer = connectionTimerStart(connectionTimer, 5000);
    ownerConnectionTimer = ownerConnectionTimerStart(ownerConnectionTimer, 5000);

    socket.addEventListener("open", connectionOpen);
    socket.addEventListener("close", reconnection);
    socket.addEventListener("message", onMessage);

    socket.down = function() {
        this.removeEventListener("open", connectionOpen);
        this.removeEventListener("close", reconnection);
        this.removeEventListener("message", onMessage);
        this.close()
        console.log('socket has been closed.')
    }

    return socket;
}

window.startPuzzle = async function () {
    function init() {
        pixi_app = null;
        end_flag = false;
        is_suspend = false;
        start_time = 0;
        suspend_time = 0;
        sound = null;
        suspend_sprite = null;
        pieceSprites = []
        borderless_arr = []
        cursor_pos = {x:0, y:0}
        cursors_sprite = []
        ownerID = null;
        grabbingPieces = {};
        multiplayerDetail;
        socket = null;
        reconnectInterval = 1250;

        multiplayerDetail = store.state.multiplayer;
        ownerID = multiplayerDetail.ownerID;
        console.log(`clientID: ${clientID}; ownerID: ${ownerID}`);
        // socket = wsInit(`${store.state.gameServerUrl}/${multiplayerDetail.wsPath}`);
        socket = wsInit(`wss://mpws.sushi334.com:8899/${multiplayerDetail.wsPath}`);
    }

    init()
    let status;
    let savedData = null;
        
    const storagedData = JSON.parse(localStorage.getItem('multiplayerDetail'))
    
    // "はじめから"を選択した場合はすでにセーブデータは削除されている
    if (storagedData !== null) {
        if (storagedData.ownerID === store.state.multiplayer.ownerID) {
            // 主犯格
            status = storagedData.puzzleDetail;
            store.state.mapStatus = storagedData.mapStatus;
            savedData = {detail: {
                // startTime: Date.now() - storagedData.score,
                startTime: Date.now(),
                suspendingTime: 0,
                piecesDetail: storagedData.piecesDetail,
                grabbingPieces: [],
            }}

            store.state.mapStatus.zoom = status.zoom;
            store.state.mapStatus.center = [status.lat, status.lng];
            store.state.mapStatus.pcs = store.getters.getPcsList.indexOf(status.pcs);
            store.state.mapStatus.map = status.map,
            store.state.isSudden      = status.isSudden;
        }
    }
    console.log(savedData)
    if (savedData === null) {
        if (ownerID == clientID) {
            status = {
                zoom: store.getters.getMapStatus.zoom,
                lat : store.getters.getMapStatus.center[0],
                lng : store.getters.getMapStatus.center[1],
                pcs : store.getters.getPcsList[store.getters.getMapStatus.pcs],
                map : store.getters.getMapStatus.map,
                isSudden: store.getters.getIsSudden,
            }
        } else {
            status = await getPuzzleDetails();
        }
    }

    draw_puzzle(status, '1000x750', true, 500, savedData).then()
}

function getPuzzleDetails() {
    return new Promise((resolve) => {
        const receiveHandler = (e) => {
            const data = e.detail;
            resolve(data.puzzleDetail);
        }
        document.addEventListener('receiveOwnerRepeat', receiveHandler);
    })
}

function restart(){
    if (start_time && is_suspend && !(end_flag)) {
        pieceSprites.forEach(s => s.interactive = true)

        suspend_sprite.visible = false

        start_time = Date.now()
        is_suspend = false
        count(suspend_time)
        return true
    } else {
        return false
    }
}

function isStarted(){
    return !!(start_time && !(end_flag))
}

function suspend(){
    if (start_time && !(is_suspend) && !(end_flag)) {

        pieceSprites.forEach(s => s.interactive = false)
        // 盤面を隠すスプライトを表示
        suspend_sprite.visible = true
        // 時間
        suspend_time = Date.now() - start_time + suspend_time
        is_suspend = true
        return true
    } else {
        return false
    }
}

function popup(isGameOver, clearTime){
  if (isGameOver) {
    store.dispatch('gameOver')
  } else {
    store.dispatch('gameClear')
    store.dispatch('clearTime', clearTime)
  }
  localStorage.removeItem('multiplayerDetail');
}

// export { restart, suspend, startPuzzle, isStarted, cleanAllVariable, intervals, }

window.restart = restart;
window.suspend = suspend;
window.isStarted = isStarted;
window.cleanAllVariable = cleanAllVariable;
